@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
  --primaryDark: #121212;
}

body{
  background: var(--primaryDark);
  color: rgb(239, 238, 238);
  font-family: 'Poppins', sans-serif;
}

.h-heading {
  @apply font-bold text-3xl mb-12 relative after:content-[""] after:absolute after:-bottom-4 after:left-0 after:w-12 after:h-1 after:bg-primaryColor after:rounded-lg
}

.section-in-about {
  @apply bg-dark-500 p-5 rounded-xl hover:bg-dark-100 ease-linear duration-300
}

.btn-btn {
  @apply text-primaryColor bg-dark-200 mt-4 shadow-md hover:bg-dark-100 hover:text-gray-100 focus:ring-[#1F1F1F]/50 font-semibold rounded-lg text-sm px-5 py-3 text-center inline-flex items-center cursor-pointer ease-linear duration-200
}

.nav-link{
  @apply hover:text-primaryColor duration-200 ease-in-out
}

.line-circle::before {
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: -20px;
  background: gray;
  display: block;
}
